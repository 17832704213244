.customer-reviews-body {
  .c-top-menu {
    display: none;
  }
  .brd_footer_main {
    display: none;
  }
}

.infl-rev-main-container {
  width: 100%;
  height: calc(100vh - 77px);
  background-color: #ccc;
  border-top: solid 1px #dce4ec;

  .rev-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    height: 100%;

    .rev-video-container {
      width: 50%;
      height: 100%;
      background-color: #000;
      position: relative;

      .close-video {
        position: absolute;
        top: 16px;
        right: 16px;
        background: rgb(64, 64, 64);
        color: #fff;
        font-size: 14px;
        padding: 10px;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 3;
        .filter-gray {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(139deg)
            brightness(109%) contrast(101%);
          width: 70%;
          height: 70%;
        }
      }
      .rev-video-box-main {
        width: 100%;
        height: 100%;
        .slick-slider {
          height: 100% !important;
          .slick-list {
            height: 100% !important;
            .slick-track {
              height: 100% !important;
              .slick-slide {
                height: 100%;
                border: none;
              }
              .slick-slide > div {
                display: flex;
                height: 100%;
              }
            }
          }
          .slick-prev,
          .slick-next {
            left: auto;
            right: 15px;
            width: 40px;
            height: 40px;
            background-color: rgb(64, 64, 64);
            color: #fff;
            z-index: 3;
            border-radius: 50%;
            &.slick-disabled {
              display: none !important;
            }
          }
          .slick-prev {
            &:before {
              content: "\f106";
              color: #fff;
              font-family: Fontawesome;
              line-height: normal;
              font-size: 30px;
              position: absolute;
              top: 3px;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
          .slick-next {
            margin-top: 50px;
            &:before {
              content: "\f107";
              color: #fff;
              font-family: "Fontawesome";
              line-height: normal;
              font-size: 30px;
              position: absolute;
              top: 5px;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
        .rev-vid-panel {
          height: 100%;
          position: relative;
          .rev-vid-panel-inr {
            height: 100%;
            aspect-ratio: 0.56 / 1;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            min-width: 400px;
            position: absolute;
            top: 0;
            background-color: #fff;

            .rev-vid-dm1 {
              display: flex;
              justify-content: center;
              width: 100%;
              height: 100%;
              position: relative;
              video {
                object-fit: cover;
                display: inline-block;
              }
              .influ-detail {
                position: absolute;
                top: 0;
                left: 10px;
                display: flex;
                align-items: center;
                margin-top: 8px;
                display: none;
                .inf-title {
                  color: #fff;
                  font-size: 1rem;
                  font-family: Nunito Sans, sans-serif !important;
                  font-weight: 700;
                }
                .infl-image {
                  width: 3rem;
                  height: 3rem;
                  border: solid 1px #fff;
                  border-radius: 50%;
                  position: relative;
                  overflow: hidden;
                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
              .influ-product-popup {
                position: absolute;
                bottom: 80px;
                right: 15px;
                display: none;
                button {
                  border: none;
                  font-size: 0.85rem;
                  font-weight: 700;
                  background: rgb(64, 64, 64);
                  color: rgb(255, 255, 255);
                  white-space: nowrap;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 6px 12px;
                  border-radius: 16px;
                  font-family: Nunito Sans, sans-serif !important;
                  &:focus {
                    box-shadow: none;
                  }
                  &:focus-visible {
                    outline: none;
                  }
                }
              }
            }
          }
          .infl-image-outer {
            position: absolute;
            top: 8px;
            left: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
            .infl-out-img {
              width: 56px;
              height: 56px;
              border: solid 1px #fff;
              border-radius: 50%;
              position: relative;
              overflow: hidden;
              display: block;
              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .indv-infl-followers{
              display: flex;
              flex-direction: column;
              .brand-followers-event{
                display: flex;
                gap: 10px;
                span{
                  font-family: Nunito Sans, sans-serif !important;
                  font-weight: 600;
                  color: #FFF;
                  strong{
                    font-weight: 800;
                  }
                }
              }
            }
            .inf-title{
              color: #fff;
              font-size: 1rem;
              font-family: Nunito Sans, sans-serif !important;
              font-weight: 700;
            }
          }
        }
      }
    }
    .rev-content-container {
      width: 50%;
      height: 100%;
      position: relative;
      background-color: gray;

      .review-container {
        padding: 24px 40px;
        height: 100%;
        overflow-y: auto;
        background-color: #fff;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          transition-duration: 0.3s;
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          transition-duration: 0.3s;
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
          border-radius: 10px;
        }
        &:hover::-webkit-scrollbar-track {
          box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
        }
        &:hover::-webkit-scrollbar-thumb {
          box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
        }
        .abc {
          height: 1200px;
          width: 100%;
        }

        .event-container {
          width: 100%;
          margin-bottom: 50px;
          position: relative;
          overflow: hidden;
          margin-top: 20px;
          padding-bottom: 20px;
          border-bottom: solid 1px #dce4ec;
          margin-bottom: 10px;
          &:first-child {
            margin-top: 0;
          }

          h3 {
            font-weight: 800 !important;
            font-size: 16px;
          }
          .slick-slider {
            margin-left: -5px;
            margin-right: -5px;

            .slick-list {
              .slick-track {
                margin: 0;
                .slick-slide {
                  padding: 0 5px;
                }
              }
            }
            .slick-prev,
            .slick-next {
              left: auto;
              width: 32px;
              height: 32px;
              background-color: rgb(64, 64, 64);
              color: #fff;
              z-index: 3;
              border-radius: 50%;
              &.slick-disabled {
                display: none !important;
              }
            }
            .slick-prev {
              left: 10px;
              &:before {
                content: "\f104";
                color: #fff;
                font-family: "Fontawesome";
                line-height: normal;
                font-size: 24px;
                position: absolute;
                top: 3px;
                left: -2px;
                right: 0;
                margin: 0 auto;
              }
            }
            .slick-next {
              right: 10px;
              &:before {
                content: "\f105";
                color: #fff;
                font-family: "Fontawesome";
                line-height: normal;
                font-size: 24px;
                position: absolute;
                top: 3px;
                left: 2px;
                right: 0;
                margin: 0 auto;
              }
            }
          }

          .event-grid {
            display: grid;
            grid-row-gap: 32px;
            grid-column-gap: 10px;
            grid-template-columns: repeat(6, 1fr);
            border-bottom: solid 1px #dce4ec;
            padding-bottom: 30px;
          }

          .event-col-box {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 155%;
            background-color: #ccc;

            .live-vid-link {
              display: block;
              overflow: hidden;
              .vid-item {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  display: block;
                }
                .vid-item-detail {
                  position: absolute;
                  display: flex;
                  background-color: rgba(0, 0, 0, 0.5);
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  padding: 8px;
                  flex-direction: column;
                  justify-content: space-between;
                  .vid-time {
                    span {
                      font-size: 0.85rem;
                      line-height: 1.25rem;
                      font-weight: 800;
                      display: inline-flex;
                      color: rgb(255, 255, 255);
                      padding: 4px 16px;
                      border-radius: 44px;
                      background: rgba(0, 0, 0, 0.6);
                      z-index: 3;
                      align-items: center;
                      font-family: Nunito Sans, sans-serif !important;
                      letter-spacing: 0.02rem;
                    }
                    .vid-views {
                      padding: 4px 6px;
                      border-radius: 4px;
                      justify-content: center;
                      align-items: center;
                      i {
                        margin-right: 6px;
                        font-size: 0.775rem;
                      }
                    }
                  }
                  .vid-title {
                    .v-title {
                      color: #fff;
                      font-family: Nunito Sans, sans-serif !important;
                      font-weight: 800;
                      text-transform: uppercase;
                      color: rgb(255, 255, 255);
                      display: -webkit-box;
                      max-width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: normal;
                      overflow-wrap: normal;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 4;
                      font-size: 1rem;
                      line-height: 1.255rem;
                    }
                    .author-name {
                      display: flex;
                      align-items: center;
                      margin-top: 8px;
                      .auth-image {
                        width: 32px;
                        height: 32px;
                        border: solid 1px #fff;
                        border-radius: 50%;
                        position: relative;
                        overflow: hidden;
                        display: none;
                        img {
                          display: block;
                          width: 100%;
                          height: 100%;
                          object-fit: contain;
                          object-position: center;
                        }
                      }
                      .auth-title {
                        color: #fff;
                        font-size: 0.875rem;
                        margin-left: 8px;
                        font-family: "SegoeUI_Medium";
                      }
                    }
                  }
                }
              }
            }
          }
          .event-product-box {
            width: 100%;
            .revi-products {
              width: 100%;
            }
            .rev-pro-image {
              width: 100%;
              position: relative;
              overflow: hidden;
              img {
                width: 100%;
              }
              .rev-buy-now {
                width: 28px;
                height: 28px;
                top: 4px;
                right: 5px;
                position: absolute;
                font-size: 1rem;
                display: inline-flex;
                color: rgb(255, 255, 255);
                padding: 0;
                border-radius: 44px;
                background: rgba(0, 0, 0, 0.6);
                z-index: 3;
                justify-content: center;
                align-items: center;
                font-family: Nunito Sans, sans-serif !important;
              }
            }
            .rev-pro-detail {
              width: 100%;
              .rev-pro-title {
                text-overflow: ellipsis;
                white-space: normal;
                overflow-wrap: normal;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                max-width: 100%;
                overflow: hidden;
                margin-top: 8px;
                margin-bottom: 5px;
                font-family: "Nunito Sans", sans-serif !important;
                font-size: 0.9rem;
                color: #000;
                font-weight: 800;
                line-height: 1.25rem;
              }
              .rev-pro-price {
                font-family: "Nunito Sans", sans-serif !important;
                font-size: 0.975rem;
                color: gray;
                font-weight: 800;
                line-height: 1rem;
              }
            }
          }
          &.event-prod-container {
            .slick-slider {
              .slick-prev,
              .slick-next {
                top: 37%;
              }
            }
          }
        }
      }
    }
  }
}

.influ-products-modal-box {
  max-width: 580px !important;
  .modal-content {
    .filter-close {
      position: absolute;
      top: 8px;
      right: 8px;
      background: rgb(64, 64, 64);
      color: #fff;
      font-size: 14px;
      padding: 8px;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 3;
      .filter-gray {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(139deg)
          brightness(109%) contrast(101%);
        width: 100%;
        height: 100%;
      }
    }
    .modal-body {
      padding: 0;
      padding-top: 10px;

      .pro-list-main {
        background-color: #fff;
        height: 100%;
        padding: 10px 0;
        border-radius: 8px;

        h2 {
          font-size: 1.15rem;
          font-weight: 800 !important;
          padding: 0 20px;
        }
        .pro-list-inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-top: 20px;
          list-style-type: none;
          overflow-y: auto;
          max-height: calc(100% - 1.5rem);
          height: 100%;
          justify-content: space-between;
          padding: 0 20px;

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          &::-webkit-scrollbar-track {
            transition-duration: 0.3s;
            box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb {
            transition-duration: 0.3s;
            box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
            border-radius: 10px;
          }
          &:hover::-webkit-scrollbar-track {
            box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
          }
          &:hover::-webkit-scrollbar-thumb {
            box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
          }

          .list-col {
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            border-bottom: solid 1px #dce4ec;
            padding-bottom: 10px;
            margin-bottom: 10px;
            &:last-child {
              padding-bottom: 0;
              border-bottom: none;
            }

            .prod-box {
              background-color: #fff;
              display: inline-flex;
              min-width: 100%;
              align-items: center;
              position: relative;
              .prod-image {
                width: 65px;
                flex-basis: 65px;
                position: relative;
                overflow: hidden;
                transition: box-shadow 0.25s;
                flex-grow: 0;
                flex-shrink: 0;
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 6px;
                }
              }
              .prod-name {
                display: flex;
                flex-grow: 1;
                margin-left: 25px;
                min-width: 0px;
                flex-direction: column;
                width: 100%;
                padding-right: 15px;
                .prod-title {
                  display: flex;
                  flex-direction: column;
                  .prod-name-inr {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                    color: #000;
                    font-size: 13px;
                    line-height: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow-wrap: normal;
                  }
                }
                .prod-price {
                  display: flex;
                  margin-top: 5px;
                  span {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 800;
                    color: #000;
                    &.original-price {
                      color: #f00;
                      margin-left: 10px;
                      text-decoration-line: line-through;
                    }
                  }
                }
              }
              .prod-cart-btn {
                display: flex;
                flex-direction: column;
                margin-right: 0;
                a {
                  background-color: #010b40;
                  border-radius: 37px;
                  color: #fff;
                  cursor: pointer;
                  font-family: Nunito Sans, sans-serif !important;
                  font-size: 12px;
                  font-weight: 700;
                  padding: 6px 0;
                  min-width: 80px;
                  text-align: center;
                  &:hover {
                    background-color: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .infl-rev-main-container {
    .rev-container {
      .rev-video-container {
        width: 100%;
        .rev-video-box-main {
          .rev-vid-panel {
            .infl-image-outer {
              display: none;
            }
            .rev-vid-panel-inr {
              .rev-vid-dm1 {
                .influ-detail {
                  display: flex;
                  .infl-image {
                    display: block;
                    margin-right: 10px;
                  }
                  .indv-infl-followers-mobile{
                    display: flex;
                    flex-direction: column;
                    .brand-followers-event{
                      display: flex;
                      gap: 10px;
                      span{
                        font-family: Nunito Sans, sans-serif !important;
                        font-weight: 600;
                        color: #FFF;
                        strong{
                          font-weight: 800;
                        }
                      }
                    }
                  }
                }
                .influ-product-popup {
                  display: block;
                }
              }
            }
          }
        }
      }
      .rev-content-container {
        width: 100%;
        .review-container {
          overflow-y: visible;
        }
      }
    }
  }
  .customer-reviews-body {
    .mobile_footer_ifti {
      display: none;
    }
  }
}
@media only screen and (max-width: 990px) {
  .customer-reviews-body {
    overscroll-behavior-y: contain;
  }
  .infl-rev-main-container {
    height: calc(100vh - 111px);
    .rev-container {
      .rev-video-container {
        .close-video {
          width: 40px;
          height: 40px;
          top: 8px;
          right: 8px;
        }
      }
      .rev-content-container {
        display: none;
      }
    }
  }
  .influ-products-modal-box {
    &.modal-dialog-centered.modal-dialog-scrollable {
      .modal-content {
        border: none;
        border-radius: 14px 14px 0 0;
      }
      max-width: 100% !important;
      width: 100%;
      height: 80%;
      margin: 0;
      min-height: calc(80% - 0rem);
      max-height: calc(80% - 0rem);
      position: fixed;
      bottom: 0;
      justify-content: flex-end;
    }
  }
}
@media only screen and (max-width: 399px) {
  .infl-rev-main-container {
    .rev-container {
      .rev-video-container {
        .rev-video-box-main {
          .rev-vid-panel {
            .rev-vid-panel-inr {
              min-width: 100%;
            }
          }
        }
      }
    }
  }
}
