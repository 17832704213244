@media only screen and (max-width: 1800px) {
    .bio-image-modal{
        max-width: 900px !important;
    }
    .bio-image-modal {
        .bio-modal-left-area {
            .bio-modal-main-content-area{
                .image-inner-box{
                    width: 45%;
                    .img-tag-area{
                        height: 400px;
                    }
                }
                .modal-content-right-area{
                    width: 53%;
                }
            }
            .react-multi-carousel-list {
                .react-multi-carousel-track {
                    .react-multi-carousel-item{
                        height: 520px;
                        
                    }
                }
            }
            .bio-image-thumbnail{
                width: 100%;
                .bio-t-img{
                    width: 70px;
                }
            }
        }
        
    }
}
@media only screen and (max-width: 1439px) {
    .c-portal-header {
        .c-top-menu {
            .c-top-menu-inner {
                ul {
                    li {
                        ul{
                            left: auto;
                            right: -20px;
                        }
                    }
                }
            }
        }
    }
    .live-event-main {
        .live-shows {
            .event-filter{
                top: -10px;
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .cat_brands_main{
        .cat_brands{
            .filter_brands{
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    .bio_shop_main_ift {
        .bio_container_main {
            .bio_shop_header {
                .bio_shop_header_left {
                    .brand-descrp{
                        .brand_logo{
                            width: 75px;
                            height: 75px;
                        }
                    }
                }
            }
            .bio_shop_body {
                .bioshop_filters_new{
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }
                .bio_cat_images {
                    .post-grid{
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1280px) {
    .pik-header{
        h3{
            margin-bottom: 0 !important;
        }
    }
}
@media only screen and (max-width: 1270px) {
    .dsk-bnr{
        display: block !important;
    }
    .lg-bnr{
        display: none !important;
    }
}
@media only screen and (max-width: 990px) {
    // .ant-drawer{
    //     z-index: 9999;
    // }

    .contact_page_main {
        margin-top: 40px;
        margin-bottom: 40px;
        .contact_content {
            width: 100%;
            
            h4 {
                font-size: 1.5rem;
            }
            p {
                font-size: 1rem;
            }
            .contact_header {
                text-align: center;
                padding-bottom: 0px;
                h2 {
                    font-size: 60px;
                    margin-bottom: 10px;
                }
                h3 {
                    font-size: 1.5rem;
                }
                p {
                    font-size: 1rem;
                }
                a {
                    padding: 7px 25px;
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
    .privacy_page_main {
        margin-top: 40px;
        margin-bottom: 40px;
        .privacy_content {
            width: 100%;
            .side-hd {
                margin-top: 0px;
                margin-bottom: 10px;
            }
            .privacy_header {
                // text-align: center;
                padding:25px 20px;
                h2 {
                    font-size: 60px;
                    margin-bottom: 10px;
                }
                h3 {
                    font-size: 1.5rem;
                }
                h5 {
                    font-size: 1.5rem;
                }
                p {
                    font-size: 1rem;
                }
                a {
                    padding: 7px 25px;
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }


    .error_page_main {
        margin-top: 40px;
        margin-bottom: 40px;
        .error_content {
            width: 100%;
            .error_header {
                text-align: center;
                h2 {
                    font-size: 60px;
                    margin-bottom: 10px;
                }
                h3 {
                    font-size: 1.5rem;
                }
                p {
                    font-size: 1rem;
                }
                a {
                    padding: 7px 25px;
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }



    .pik-header{
        
        h3{
            font-size: 1.5rem;
        }
    }
    .c-portal-banner-main{
        margin-top: 30px;
        .c-portal-banner{

            .slick-list{
                margin-left: 0;
                margin-right: 0;
                &:after{
                    display: none;
                }
            }
            .slick-track{
                margin: 0;
                .slick-slide{
                    padding: 0;
                }
            }
            .bnr-height{
                .top-bnr-gap{
                    padding: 0;
                    border-radius: 8px;
                }
            }
        }
    }
    .top_picks_main{
        margin-top: 40px;
    }
    .ant-drawer-content{
        .ant-drawer-header{
            padding-left: 16px;
            padding-right: 16px;
            .ant-drawer-header-title{
                button{
                    padding-left:0;
                    font-size: 18px;
                    color: #000;
                }
            }
        }
        .ant-drawer-body{
            padding-left: 0;
            padding-right: 0;
            padding-top: 30px;

            .my-account-mobile-menu{
                width: 100%;
                margin-bottom: 20px;
                border-bottom: solid 1px #ccc;
                .customer-box{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    border-top: solid 1px #ccc;
                    padding: 6px 10px;
                    position: relative;
                    justify-content: center;
                    .cus-name-mob{
                        font-family: "Bitter", serif;
                        font-weight: 700;
                        font-style: normal;
                        color: #252525;
                        font-size: 16px;
                        line-height: 22px;
                        padding-bottom: 0;
                    }
                    .cus-id-mob{
                        font-size: 14px;
                        line-height: 18px;
                        color: #7b8994;
                    }
                    i{
                        position: absolute;
                        font-size: 20px;
                        right: 10px;
                    }
                }
                .account-mob-menu{
                    display: none;
                    ul{
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        padding-left: 25px;
                        padding-right: 20px;
                        padding-bottom: 10px;
                        li{
                            
                            a{
                                font-family: Nunito Sans, sans-serif !important;
                                color: #7b8994;
                                display: block;
                                width: 100%;
                                padding: 8px 0;
                                display: flex;
                                align-items: center;
                                font-weight: 600;
                                i{
                                    padding-right: 8px;
                                    font-size: 12px;
                                }
                                &.active{
                                    color: #000;
                                    font-weight: 800;
                                }
                            }
                        }
                    }
                    &.pro-ul-mob-open{
                        display: block;
                    }
                }
            }

            .sign-mobile-drawer{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 20px;
                h4{
                    font-size: 1.3rem;
                    font-weight: 400 !important;
                    margin-bottom: 16px;
                    text-align: center;
                    span{
                        font-weight: 700;
                    }
                }
                .sign-mb-btns{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    grid-gap: 16px;
                    justify-content: space-between;
                    text-align: center;
                    border-bottom: solid 1px #b9b9b9;
                    padding-bottom: 32px;
                    margin-bottom: 32px;
                    span{
                        display: inline-flex;
                        flex: 1 1 0%;
                        border-radius: 100px;
                        padding-left: 16px;
                        padding-right: 16px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        transition: all 200ms ease 0s;
                        box-shadow: 0px 4px 8px #0a164626;
                        justify-content: center;
                        color: #FFF;
                        font-family: Nunito Sans,sans-serif!important;
                        font-weight: 700;
                        background-color: #010b40;
                        font-size: 16px;
                    }
                }
            }
            .dr-mobi-menu-mode{
                position: absolute;
                right: 16px;
                top: 20px;

                a{
                    background-color: #010b40;
                    border-radius: 37px;
                    color: #fff;
                    cursor: pointer;
                    font-family: Nunito Sans,sans-serif!important;
                    font-size: 14px;
                    font-weight: 700;
                    padding: 8px 23px;
                    
                    i{
                        padding-right: 7px;
                    }
                }
            }

            .dr-mobi-menu-ift{
                display: flex;
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                ul{
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    li{
                        width: 100%;
                        a{
                            font-family: "Nunito Sans", sans-serif !important;
                            color: #7b8994;
                            font-weight: 600;
                            font-size: 16px;
                            margin-bottom: 32px;
                            width: 100%;
                            display: inline-flex;
                            align-items: center;
                            line-height: 1.5;
                            &.active{
                                color: #000;
                                font-weight: 800;
                            }
                            .fa{
                                padding-right: 10px;
                            }
                        }
                        ul{
                            &.mobile-inr-inr-submenu-close{
                                display: none;
                            }
                            &.mobile-inr-inr-submenu-open{
                                display: block;
                            }
                            padding-left: 25px;
                            padding-right: 20px;
                            padding-bottom: 10px;
                            margin-top: -25px;
                            li{
                                a{
                                    padding: 12px 0;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .c-portal-header{
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        background-color: #FFF;
        z-index: 9;
        transition: box-shadow 0.5s ease 0s;
        border-bottom: solid 1px #ccc;
        
        &.sticky-shadow{
            box-shadow: 0px 4px 8px #0a164626;
        }
        .p-h-inner{
            flex-direction: column;
            position: relative;
            padding: 12px 0;

            .mobile-menu-ifti{
                display: block;
                position: absolute;
                left: 0;
                width: 32px;
                height: 32px;
                border: solid 1px #CCC;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .barsBtn{
                    img{
                        width: 16px;
                        display: block;
                    }
                }
            }
    
            .p-h-left{
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                a{
                    .logo{
                        width: 70px;
                        margin-left: 42px;
                    }
                }
                .mobile-search-container{
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    .mobile-search-box{
                        display: flex;

                        .mobile-search-icon{
                            align-items: center;
                            border: solid 1px #CCC;
                            border-radius: 100%;
                            color: #fff;
                            cursor: pointer;
                            display: flex;
                            font-size: 14px;
                            height: 32px;
                            width: 32px;
                            justify-content: center;
                            padding: 7px;
                            z-index: 3;
                            img{
                                -webkit-filter: invert(0%) sepia(19%) saturate(107%) hue-rotate(314deg) brightness(107%) contrast(106%);
                                filter: invert(0%) sepia(19%) saturate(107%) hue-rotate(314deg) brightness(107%) contrast(106%);
                                height: 100%;
                                width: 100%;
                            }
                        }

                        .mobile-serach-div{
                            display: none;
                            &.mobile-search{
                                position: absolute;
                                width: 100%;
                                top: 0;
                                left: 0;
                                background: #FFF;
                                height: 56px;
                                display: flex;
                                align-items: center;
                                padding: 0;
                                z-index: 3;
                                justify-content: space-between;
                                .mobile-search-inner{
                                    flex: 0 0 72%;
                                    max-width: 72%;
                                    input[type=search]{
                                        padding: 8px 16px 8px 42px;
                                        background: rgb(247, 247, 247);
                                        border: 1px solid rgb(230, 232, 242);
                                        border-radius: 100px;
                                        background-position: center center;
                                        background-repeat: no-repeat;
                                        background-size: 20px;
                                        background-position: 12px center;
                                        background-image: url(../images/brand-logos/search.svg);
                                        line-height: 1.5rem;
                                        min-height: 40px;
                                    }
                                }
                                .close-search{
                                    width: 26%;
                                    font-family: "Nunito Sans", sans-serif !important;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 700;
                                    font-size: 14px;
                                    border: 1px solid rgb(230, 232, 242);
                                    border-radius: 100px;
                                    height: 40px;
                                }
                            }
                        }
                    }
                    .brand-inf-mode-mobile{
                        a{
                            background-color: #010b40;
                            border-radius: 37px;
                            color: #fff;
                            cursor: pointer;
                            font-family: Nunito Sans, sans-serif !important;
                            font-size: 14px;
                            font-weight: 700;
                            padding: 8px 23px;
                        }
                    }
                }
            }
            .p-h-middle{
                flex: 0 0 100%;
                max-width: 100%;
                display: none;
    
                .form-control{
                    border-radius: 0.50rem !important;
                    height: 48px;
                    border-color: #737373 !important;
    
                }
    
                .input-group-append{
                    display: none;
                }
            }
            .p-h-user{
                display: none;
            }
    
            .login-signup{
                display: none;
            }
        }
    
        .c-top-menu{
            display: none;
        }
    }
    .mobile-front-menu{
        width: 100%;
        background-color: rgb(247, 247, 247);
        padding: 10px 0;
        display: block;
        .slick-slider{
            margin-left: -20px;
            margin-right: -20px;
            .slick-list{
                .slick-track{
                    .slick-slide{
                        padding: 0 20px;
                    }
                }
            }
        }
        .nav-link-front{
            
            a{
                padding: 5px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Nunito Sans", sans-serif !important;
                font-size: 14px;
                color: #000;
                font-weight: 700;
                &.active{
                    border-bottom: solid 2px #000;
                }
            }
        }
    }

    .brand_you_love_main{
        margin-top: 40px;
        padding-top: 40px;
        padding-bottom: 40px;

        .brand_you_love{
            .brand-item-lv{
                .lv-detail{
                    .lv-d1{
                        .lv-name{
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                    .lv-d2{
                        .lv-off{
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                }
            }
        } 
    }
    .cat_brands_main{
        margin-top: 40px;
        margin-bottom: 40px;
        
        .cat_brands{
            .filter_brands{
                grid-template-columns: repeat(2, 1fr);
            }
            .categ-button{
                padding-top: 3px;
                padding-bottom: 3px;
                margin: 0 4px;
            }
        }
    }
    .daily_deals_main{
        margin-top: 40px;
        margin-bottom: 40px;
        .daily_deals{
            .dail_deal_caursol{
                .deal-item{
                    .deal-box{
                        .deal-detail{
                            .dl-1, .dl-2{
                                span.dl-name{
                                    font-size: 14px;
                                }
                                span.dl-off{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cash_back_main{
        margin-bottom: 40px;

        .cash_back{
            .cash_back_caursol{
                .slick-list{
                    margin-left: 0;
                    margin-right: 0;
                    .slick-slide{
                        padding: 0;
                    }
                }
            }
            .cash-item{
                padding: 0;
            }
        }
    }
    .cat_brands_main{
        .cat_brands{
            .all_categories_ift{
                grid-template-columns:repeat(4, minmax(0, 1fr));
            }
        }
    }
    .sub-category-banner-main{
        margin-top: 40px;
        .sub-category-banner{
            .slick-list{
                margin-left: 0;
                margin-right: 0;
                &:after{
                    display: none;
                }

                .slick-slide{
                    padding: 0;
                }
            }
        }
    }
    .popular_cat_main{
        margin-top: 40px;
    }
    .bio_shop_main_ift {
        margin-top: 35px;
        .bio_container_main {
            .bio_shop_body {
                .bio_cat_images {
                    .post-grid{
                        grid-row-gap: 22px;
                        grid-column-gap: 14px;
                    }
                }
            }
        }
    }
    .bio-image-modal{
        max-width: 650px !important;
        .bio-modal-left-area {
            .bio-modal-main-content-area {
                .modal-content-right-area {
                    .cat-pro-buttons{
                        flex-flow: row wrap;
                        gap: 0;
                        justify-content: space-between;
                        span{
                            padding-left: 0;
                            font-size: 12px;
                            display: block;
                            flex: 0 0 48%;
                            max-width: 48%;
                            margin-bottom: 10px;
                            i{
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
            .bio-image-thumbnail{
                .bio-t-img{
                    width: 70px;
                }
            }
        }
    }
    .brd_footer_main {
        .brd_footer {
            .f-right-temporary {
                .terms-link-temporary {
                    ul {
                        li{
                            &:first-child{
                                padding-left: 0;
                            }
                            &:last-child{
                                padding-right: 0;
                            }
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 767px) {

    .pik-header {
        .sort-filter{
            padding-right: 20px;
            .ant-select-lg{
                width: 140px;
            }
        }
    }

    .bio_shop_main_ift {
        .bio_container_main {
            .bio_shop_header {
                flex-direction: column;
                .bio_shop_header_left{
                    flex-basis: 100%;
                    width: 100%;
                    padding: 0 20px;
                }
                .bio_shop_header_topmenu{
                    padding: 0 20px;
                    flex-flow: row wrap;
                    a{
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                }
                .bio_shop_header_center {
                    .bio_shop_cat_slider {
                        .carousel-items {
                            a {
                                .cat_height{
                                    height: 72px;
                                }
                                span{
                                    height: 72px;
                                    font-size: 12px;
                                    text-align: center;
                                    padding: 0 5px;
                                }
                            }
                        }
                        .react-multi-carousel-list{
                            margin: 0 0 0 8px;
                        }
                    }
                }
                .bio_shop_header_right {
                    top: 10px;
                    right: 10px;
                    button{
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .error_page_main {
        .error_content {
            .error_header {
                h2 {
                    font-size: 60px;
                }
                .error-img {
                    margin-bottom: 20px;
                    width: 200px;
                }
                h3 {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                p {
                    color: #575757;
                    font-weight: 400 !important;
                    font-size: 14px;
                    margin-bottom: 0;
                }
                a {
                    font-size: 13px;
                }
            }
        }
    }

    .c-portal-banner-main{
        .midd-width-ifti{
            padding-inline-end: 0;
            .c-portal-banner{

                .dsk-bnr{
                    display: none !important;
                }
                .iph-bnr{
                    display: block !important;
                }

                .slick-list{
                    margin-left: -10px;

                    .slick-slide{
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                .bnr-height{
                    height: 178px;
                }
            }
        }
    }
    .brand_you_love_main{
        .brand_you_love{
            .brand-item-lv{
                margin: 0 10px;
            }
        }
    }
    .daily_deals_main{
        .daily_deals{
            .dail_deal_caursol{
                .deal-item{
                    margin: 0 10px;
                }
            }
        }
    }
    .cash_back_main{
        .midd-width-ifti{
            padding-inline-end: 0;
        }
        .cash_back{
            .cash_back_caursol{
                .slick-list{
                    margin-left: -10px;
                    .slick-slide{
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                .slick-slider{
                    margin-right: 0;
                }
            }
            .cash-item{
                height: 175px;
            }
        }
    }
    .brd_footer_main{
        display: none;
    }
    .mobile_footer_ifti{
        display: block;
        background-color: #252525;

        .ant-collapse{
            background-color: #252525;

            .ant-collapse-header{
                font-family: Nunito Sans,sans-serif!important;
                text-transform: uppercase;
                color:#FFF;
                opacity: .7;
            }
            .ant-collapse-content{
                background-color: #252525;
                border: none;
                .ant-collapse-content-box{
                    padding-top: 0;

                    .brd-footer-box{
                        ul{
                            list-style-type: none;
                            margin: 0;
                            padding: 0;

                            li{
                                width: 100%;

                                a{ 
                                    color: #fff;
                                    display: block;
                                    font-size: 14px;
                                    padding-bottom: 5px;
                                    padding: 10px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .brd_footer{
            width: 100%;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            .brd-right{
                align-items: center;
                display: flex;

                .brd-social{
                    display: flex;
                    flex-direction: row;
                    grid-column-gap: 10px;

                    .fa-stack{
                        font-size: 1.5rem;
                        .fa-stack-2x{
                            color: #FFF;
                            transition: all 0.2s ease;
                            opacity: 0.7;
                        }
                        .fa-stack-1x {
                            color: #000;
                            transition: all 0.2s ease;
                            font-size: 1rem;
                        }
                    }
                }
            }
            .brd-copyright{
                color: #fff;
                font-size: 1rem;
                margin-top: 10px;
            }
        }
    }
    .popular_cat_main{
        
        .midd-width-ifti{
            padding-inline-end: 0;
        }
        .popular_cat{
            .popular_cat_carousal{
                .react-multi-carousel-list{
                    margin-left: -7px;
                    margin-right: 0;
                    .react-multi-carousel-track{
                        .react-multi-carousel-item{
                            padding-left: 7px;
                            padding-right: 7px;
                        }
                    }
                }
            }
            .cash-item{
                .cat_height{
                    height: 72px;
                }
                h4{
                    height: 72px;
                    text-align: center;
                    font-size: 12px;
                    padding: 0 5px;
                }
            }
        }
    }
    .cat_brands_main{
        .cat_brands{
            .all_categories_ift{
                grid-template-columns:repeat(2, minmax(0, 1fr));

                .indv-categ {
                    a {
                        .categ-boxes{
                            height: 72px;
                        }
                        h4{
                            height: 72px;
                            text-align: center;
                            font-size: 12px;
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }

    .sub-category-banner-main{   

        .midd-width-ifti{
            padding-inline-end: 0;
        }

        .sub-category-banner{
            .slick-list{
                margin-left: -10px;
                .slick-slide{
                    padding-left: 10px;
                    padding-right: 10px;
                    .bnr-height{
                        height: 178px;
                        .dsk-bnr{
                            display: none !important;
                        }
                        .iph-bnr{
                            display: block !important;
                        }
                    }
                }
            }
        }
        &.sub-sub-bnr-main{
            padding-inline-end: 20px;
            .sub-sub-category-banner{
                height: 178px;

                span{
                    font-size: 20px;
                }
            }
        }
    }
    .bio-image-modal{
        max-width: 100% !important;
        margin: 0;
        .bio-modal-left-area {
            .react-multi-carousel-list {
                .react-multi-carousel-track{
                    .react-multi-carousel-item{
                        height: auto;
                        .bio-modal-main-content-area{
                            height: auto;
                            flex-direction: column;
                            .image-inner-box{
                                width: 100%;
                                .img-tag-area{
                                    height: auto;
                                    .image-inner-media{
                                        padding: 0;
                                        border: none;
                                    }
                                }
                            }
                            .modal-content-right-area{
                                width: 100%;
                                min-height: 200px;
                            }
                        }
                    }
                }
                .react-multiple-carousel__arrow{
                    display: none;
                }
            }
        }
        &.bio-image-modal{
            .bio-modal-left-area {
                .bio-modal-main-content-area {
                    .image-inner-box {
                        video{
                            border: none;
                            padding: 0;
                        }
                    }
                }
                .react-multi-carousel-list {
                    .react-multi-carousel-track {
                        .react-multi-carousel-item {
                            .bio-modal-main-content-area {
                                .image-inner-box {
                                    .img-tag-area{
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bio_shop_main_ift {
        margin-top: 10px;
        .midd-width-ifti{
            padding: 0;
        }
        .bio_container_main {
            .bio_shop_body {
                .bioshop_filters_new{
                    padding: 0 5px;
                    grid-column-gap: 5px;
                    .post-type {
                        &.category-filter {
                            .category-box{
                                height: 35px;
                            }
                            .category-box-all {
                                left: -95px;
                                .all-inner{
                                    width: 320px;
                                    .main-cat-right {
                                        .cat-filter-box {
                                            button {
                                                font-size: 0.775rem;
                                            }
                                        }
                                    }
                                    .main-cat-left {
                                        .cat-filter-box {
                                            button{
                                                font-size: 0.775rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .ant-select-lg {
                            .ant-select-selector{
                                height: 35px;
                            }
                        }
                    }
                }
                .bio_shop_cat_slider {
                    .react-multi-carousel-list{
                        margin: 0 0 0 8px;
                    }
                    .carousel-items {
                        a {
                            .cat_height{
                                height: 72px;
                            }
                            span{
                                height: 72px;
                                font-size: 12px;
                                text-align: center;
                                padding: 0 5px;
                            }
                        }
                    }
                    &.sub-cat-slider {
                        .carousel-items {
                            a {
                                .cat_height{
                                    height: 40px !important;
                                }
                                span{
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
                .bio_cat_images {
                    .post-grid {
                        margin-top: 10px;
                        grid-row-gap: 0;
                        grid-column-gap: 0;
                        .post-image {
                            .image-box{
                                span{
                                    border-radius: 5px;
                                }
                                &.play-button{
                                    i{
                                        font-size: 50px;
                                    }
                                }
                            }
                            .cat-pro-detail{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .bio-image-modal {
        .modal-content{
            min-height: 100vh;
        }
        .bio-modal-left-area {
            .bio-image-thumbnail {
                .bio-t-img{
                    width: 50px;
                }
            }
            .bio-modal-main-content-area {
                .modal-content-right-area {
                    .pro-cat-description{
                        margin: 7px 0;
                        -webkit-line-clamp: 3;
                    }
                    .cat-pro-buttons{
                        flex-flow: row wrap;
                        gap: 0;
                        justify-content: space-between;
                        span{
                            padding-left: 0;
                            font-size: 12px;
                            display: block;
                            flex: 0 0 48%;
                            max-width: 48%;
                            margin-bottom: 10px;
                            i{
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
    .sub-sub-category-banner{
        height: 178px;
        
            
            .dsk-bnr{
                display: none !important;
            }
            .iph-bnr{
                display: block !important;
            }
        
    }

    .contact-us-form-kb{
        .row{
            .col-6{
                flex: 0 0 100%;
                max-width: 100%;

                .ant-form-item{
                    margin-bottom: 15px;
                }
            }
        }
    }
    .privacy_page_main .privacy_content .privacy_header .faq-container-ift .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text{
        font-size: 14px;
    }
    
    
}
@media only screen and (max-width: 500px) {
    .ant-picker-panel-container  {
        width: 90%;
        .ant-picker-panels {
            display: block;
        }
    }
    .ant-picker-range-wrapper {
        &.ant-picker-date-range-wrapper {
            min-width: auto!important;
            max-width: 340px;
        }
    }
    .top_picks_main{
        .top_picks{
            .brand-logos{
                .indv-brand{
                    margin-bottom: 20px;
                }
            }
        }
    }
    .cat_brands_main{
        .midd-width-ifti{
            padding-inline-end: 0;
        }
        &.all-cat-page{
            padding-inline-end: 20px;
        }
        .cat_brands{
            .filter_brands{
                grid-template-columns: repeat(1, 1fr);
                padding-inline-end: 20px;
                grid-row-gap: 20px;

                .ft_box{
                    .ft_box_link{
                        box-shadow: 0px 0px 8px rgb(10 22 70 / 15%);
                        border-radius: 6px;
                        .ft_logo{
                            box-shadow: none;
                        }
                    }
                }
            }
            .slick-list{
                margin: 0;
            }
            .slick-slider{
                padding: 0 0;
            }
        }
    }
}