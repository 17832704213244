.load-container{
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  justify-content: center;
  align-items: center;
}
.ant-notification-notice{
  padding: 15px;
  border-radius: 8px;
  .ant-notification-notice-btn{
    width: 100%;
    margin-top: 10px;
    button{
      width: 100%;
      background-color: #010b40;
      border-color: #010b40;
      &:hover{
        background-color: #000;
      }
    }
  }
    .ant-notification-notice-message{
      font-family: "Nunito Sans", sans-serif;
      font-weight: 700;
    }
}
.added-cart{
  background-color: #fff;
  display: inline-flex;
  min-width: 100%;
  position: relative;
  padding: 0;
  .added-prod-image{
    width: 60px;
    flex-basis: 60px;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.25s;
    flex-grow: 0;
    flex-shrink: 0;
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .added-prod-name{
    display: flex;
    flex-grow: 1;
    margin-left: 15px;
    min-width: 0px;
    flex-direction: column;
    width: 100%;
    padding-right: 0;
    .added-prod-title{
      display: flex;
      flex-direction: column;
      .added-prod-name-inr{
        font-family: "Nunito Sans", sans-serif !important;
        font-weight: 700;
        color: #000;
        font-size: 0.85rem;
        line-height: 18px;
        display: -webkit-box;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        overflow-wrap: normal;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .added-prod-veriants-inr{
        font-family: "Nunito Sans", sans-serif !important;
        font-weight: 700;
        color: #000;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .added-prod-price{
      display: flex;
      margin-top: 5px;
      span{
        font-family: "Nunito Sans", sans-serif !important;
        font-weight: 800;
        color: #000;
      }
    }
  }
}
.checkout-container{
    height: calc(100vh - 134px);
    padding: 0;
    border-radius: 8px;

    .checkout-list-container{
        background-color: #fff;
        border-radius: 8px;
        height: 100%;
        padding: 20px 0;
        position: relative;

        .checkout-list-inner{
            display: flex;
            flex-direction: column;
            height: 100%;
            list-style-type: none;
            max-height: calc(100% - 55px);
            overflow-y: auto;
            padding: 0 20px;
            width: 100%;

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
              }
              &::-webkit-scrollbar-track {
                transition-duration: 0.3s;
                box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
                border-radius: 10px;
              }
              &::-webkit-scrollbar-thumb {
                transition-duration: 0.3s;
                box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
                border-radius: 10px;
              }
              &:hover::-webkit-scrollbar-track {
                box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
              }
              &:hover::-webkit-scrollbar-thumb {
                box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
              }

            .product-check-detail{
                width: 100%;
                .prod-image{
                    width: 100%;
                    max-width: fit-content;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .prod-image-left{
                      width: 100%;
                      img{
                        width: 100%;
                         object-fit: contain;
                     }
                    }
                }
                .prod-title-price{
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  margin-top: 10px;
                  h6{
                    font-weight: 800 !important;
                  }
                  .shp-price{
                    color: #000;
                    font-family: Nunito Sans,sans-serif!important;
                    font-weight: 800;
                    
                  }
                }
                .prod-veriants{
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  .variant-column{
                    margin-top: 15px;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    .ant-form-item{
                      margin-bottom: 0;
                    }
                    label{
                      font-weight: 800;
                      font-family: Nunito Sans, sans-serif !important;
                    }
                    .ant-select-lg{
                      font-size: 14px;
                      .ant-select-selector{
                        border-color: #000;
                      }
                    }
                    &.variant-gap{
                      margin-bottom: 0;
                    }
                  }
                }
                .checkout-buttons{
                  display: flex;
                  width: 100%;
                  background-color: #FFF;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  padding: 10px 20px;
                  border-radius: 0 0 8px 8px;
                  button{
                    width: 100%;
                    padding: 0.7rem 1rem;
                    background-color: #010b40;
                    border-color: #010b40;
                    font-family: Nunito Sans,sans-serif!important;
                    font-weight: 700;
                    &:hover{
                      background-color: #000;
                      border-color: #000;
                    }
                  }
                }
            }

            .shipping-information{
              width: 100%;
              h6{
                font-weight: 800 !important;
                padding-bottom: 5px;
                margin-bottom: 10px;
                margin-top: 5px;
              }
              .ant-tabs-nav-list{
                width: 100%;
                justify-content: space-between;
                .ant-tabs-tab{
                  .ant-tabs-tab-btn{
                    color: #7b8994;
                    font-family: Nunito Sans,sans-serif!important;
                    font-size: 1rem;
                  }
                  &.ant-tabs-tab-active{
                    .ant-tabs-tab-btn{
                      color: #3c3b3b;
                    }
                  }
                }
                .ant-tabs-ink-bar{
                  background-color: #3c3b3b;
                }
              }
              .ship-info-column{
                display: flex;
                width: 100%;
                gap: 10px;
                .ship-info-col-group{
                  width: 50%;
                }
                .ant-form-item{
                  width: 100%;
                }
                .bill-ship{
                  margin-left: 10px;
                  font-size: 12px;
                }
              }
            }

            .shipping-from-front{
              display: flex;
              width: 100%;
              height: 100%;
              .account-left-content{
                width: 100%;
                h1{
                  font-size: 1.15rem;
                  font-weight: 800 !important;
                }
                .account-detail{
                  width: 100%;
                  max-width: 100%;
                  padding: 0;
                  .card-information-store{
                    width: 100%;
                    .billing-address-store{
                      width: 100%;
                      margin-top: 10px;
                      .cd-detail-box {
                        width: 100%;
                        display: flex;
                        padding: 10px 0;
                        .ant-form-item{
                            margin-bottom: 0;
                        }
                        &.cd-detail-box-gap {
                          gap: 10px;
                        }
                        .float-label {
                          width: 100%;
                          .ant-form-item{
                            margin-bottom: 0;
                          }
                          .ant-input-lg {
                            height: 40px;
                            font-size: 14px;
                          }
                          .ant-select-selector{
                            height: 40px;
                            font-size: 14px;
                          }
                          label{
                            background-color: #FFF;
                            width: 90%;
                            &.label-float{
                                width: auto;
                            }
                          }
                        }
                        .cd-2col {
                          width: 50%;
                        }
                        .ant-checkbox-inner {
                          width: 18px;
                          height: 18px;
                        }
                        button {
                            background-color: #010b40;
                            border-color: #010b40;
                            color: #fff;
                            font-family: Nunito Sans, sans-serif !important;
                            font-weight: 700;
                            border-radius: 4px;
                            display: inline-block;
                            font-size: 14px;
                            cursor: pointer;
                            width: 100%;
                            &:hover{
                                background-color: #000;
                            }
                        }
                      }
                    }
                    .address-list{
                      width: 100%;
                      display: grid;
                      gap: 15px;
                      margin-top: 15px;
                      grid-template-columns: repeat(1, 1fr);
                      .address-box{
                          width: 100%;
                          border: solid 1px #d9d9d9;
                          border-radius: 4px;
                          min-height: 140px;
                          padding: 16px;
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          position: relative;
                          &.dft-box{
                              background-color: #fafafa;
                              border-color: #000;
                          }
                          h4{
                              font-size: 1rem;
                              font-weight: 700 !important;
                              color: #737373;
                          }
                          .ship-address-iner{
                              display: flex;
                              width: 100%;
                              flex-direction: column;
                              font-family: "Nunito Sans", sans-serif !important;
                              font-weight: 700;
                          }
                          .ship-btns{
                              display: flex;
                              gap: 10px;
                              margin-top: 10px;
                              button{
                                  background-color: #010b40;
                                  border-color: #010b40;
                                  color: #fff;
                                  border-radius: 4px;
                                  min-width: 80px;
                                  &:hover{
                                      background-color: #000;
                                  }
                              }
                          }
                          .ant-checkbox-wrapper{
                              position: absolute;
                              top: 16px;
                              right: 16px;
                          }
                      }
                    }
                  }
                }
              }
            }
            .payment-from-front{
              display: flex;
              width: 100%;
              height: 100%;
              .account-left-content{
                width: 100%;
                h1{
                  font-size: 1.15rem;
                  font-weight: 800 !important;
                }
                .account-detail{
                  width: 100%;
                  .card-information-store{
                    width: 100%;
                    .add-card-detail{
                      height: 45px;
                      border: solid 1px #d9d9d9;
                      display: flex;
                      width: 100%;
                      font-family: Nunito Sans, sans-serif !important;
                      font-size: 16px;
                      font-weight: 800;
                      text-transform: uppercase;
                      align-items: center;
                      padding-left: 12px;
                      margin: 10px 0;
                      position: relative;
                      cursor: pointer;
                      i{
                        margin-right: 10px;
                      }
                      .selected-cradit-card{
                        position: absolute;
                        right: 20px;
                        i{
                          margin-right: 0;
                        }
                      }
                    }
                    .cd-detail-box {
                      width: 100%;
                      display: flex;
                      padding: 10px 0;
                      .ant-form-item{
                          margin-bottom: 0;
                      }
                      &.cd-detail-box-gap {
                        gap: 10px;
                      }
                      .float-label {
                        width: 100%;
                        .ant-form-item{
                          margin-bottom: 0;
                        }
                        .ant-input-lg {
                          height: 45px;
                        }
                        .ant-select-selector{
                          height: 45px;
                        }
                        label{
                          background-color: #FFF;
                          width: 90%;
                          &.label-float{
                              width: auto;
                          }
                        }
                      }
                      .cd-2col {
                        width: 50%;
                      }
                      .ant-checkbox-inner {
                        width: 18px;
                        height: 18px;
                      }
                      button {
                          background-color: #010b40;
                          border-color: #010b40;
                          color: #fff;
                          font-family: Nunito Sans, sans-serif !important;
                          font-weight: 700;
                          border-radius: 37px;
                          display: inline-block;
                          font-size: 16px;
                          cursor: pointer;
                          width: 100%;
                          &:hover{
                              background-color: #000;
                          }
                      }
                    }
                  }
                }
              }
            }
        }
    }
}
.modal-shipping-from-front{
  .modal-content{
    .close-shipping-front{
      color: #fff;
      font-size: 14px;
      padding: 10px;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      right: 7px;
      z-index: 2;
      top: 9px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .modal-body{
      .account-left-content{
        width: 100%;
        h1{
          font-size: 1.15rem;
          font-weight: 800 !important;
          display: flex;
          justify-content: space-between;
          border-bottom: solid 1px #d9d9d9;
          position: relative;
          height: 40px;
          align-items: center;
            .plus-address {
              position: absolute;
              right: 30px;
              display: flex;
              top: 0;
              align-items: center;
              text-transform: uppercase;
              font-family: "Nunito Sans", sans-serif !important;
              font-weight: 800;
              i {
                padding-right: 10px;
            }
            &:hover{
              background-color: #000;
              color: #FFF;
              border-color: #000;
            }
          }
        }
        .account-detail{
          width: 100%;
          max-width: 100%;
          padding: 0;
          .card-information-store{
            width: 100%;
            .billing-address-store{
              width: 100%;
              margin-top: 10px;
              .cd-detail-box {
                width: 100%;
                display: flex;
                padding: 10px 0;
                .ant-form-item{
                    margin-bottom: 0;
                }
                &.cd-detail-box-gap {
                  gap: 10px;
                }
                .float-label {
                  width: 100%;
                  .ant-form-item{
                    margin-bottom: 0;
                  }
                  .ant-input-lg {
                    height: 40px;
                    font-size: 14px;
                  }
                  .ant-select-selector{
                    height: 40px;
                    font-size: 14px;
                  }
                  label{
                    background-color: #FFF;
                    width: 90%;
                    &.label-float{
                        width: auto;
                    }
                  }
                }
                .cd-2col {
                  width: 50%;
                }
                .ant-checkbox-inner {
                  width: 18px;
                  height: 18px;
                }
                button {
                    background-color: #010b40;
                    border-color: #010b40;
                    color: #fff;
                    font-family: Nunito Sans, sans-serif !important;
                    font-weight: 700;
                    border-radius: 4px;
                    display: inline-block;
                    font-size: 14px;
                    cursor: pointer;
                    width: 100%;
                    &:hover{
                        background-color: #000;
                    }
                }
              }
            }
            .address-list{
              width: 100%;
              display: grid;
              gap: 15px;
              margin-top: 15px;
              grid-template-columns: repeat(1, 1fr);
              .address-box{
                  width: 100%;
                  border: solid 1px #d9d9d9;
                  border-radius: 4px;
                  min-height: 140px;
                  padding: 16px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  position: relative;
                  &.dft-box{
                      background-color: #fafafa;
                      border-color: #000;
                  }
                  h4{
                      font-size: 1rem;
                      font-weight: 700 !important;
                      color: #737373;
                  }
                  .ship-address-iner{
                      display: flex;
                      width: 100%;
                      flex-direction: column;
                      font-family: "Nunito Sans", sans-serif !important;
                      font-weight: 700;
                  }
                  .ship-btns{
                      display: flex;
                      gap: 10px;
                      margin-top: 10px;
                      button{
                          background-color: #010b40;
                          border-color: #010b40;
                          color: #fff;
                          border-radius: 4px;
                          min-width: 80px;
                          &:hover{
                              background-color: #000;
                          }
                      }
                  }
                  .ant-checkbox-wrapper{
                      position: absolute;
                      bottom: 22px;
                      right: 16px;
                  }
              }
            }
          }
        }
      }
    }
  }
}