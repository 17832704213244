.influencer-layout-main {
  width: 100%;
  // padding-inline-end: 24px;
  // padding-inline-start: 24px;
  margin-top: 30px;
  position: relative;
  @media screen and (max-width: 600px) {
      // padding-inline-end: 20px;
      // padding-inline-start: 20px;
  }
  .event-filter{
    position: absolute;
    right: 24px;
    top: -45px;
    z-index: 1;
    button{
        background-color: #fff;
        color: #000;
        border-color: #ccc;
        font-family: Nunito Sans, sans-serif !important;
        font-size: 16px;
        font-weight: 700;
        border-radius: 22px;
        padding-left: 20px;
        padding-right: 20px;
        &:hover{
            background-color: #000;
            color: #FFF;
            border-color: #000;
        }
    }
  }
  .influencer-layout-inner {
    display: grid;
    grid-row-gap: 32px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    .event-col-box {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 133%;
      background: #eee;

      .live-vid-link {
        display: block;
        overflow: hidden;
        .vid-item {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: #ddd;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
          .vid-item-detail {
            position: absolute;
            display: flex;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 8px;
            flex-direction: column;
            justify-content: space-between;
            .vid-time {
              display: flex;
              justify-content: space-between;
              span {
                font-size: 0.85rem;
                line-height: 1.25rem;
                font-weight: 800;
                display: inline-flex;
                color: rgb(255, 255, 255);
                padding: 4px 16px;
                border-radius: 44px;
                background: rgba(0, 0, 0, 0.6);
                z-index: 3;
                align-items: center;
                font-family: Nunito Sans, sans-serif !important;
                letter-spacing: 0.02rem;
                &.off-percentage {
                  color: #f85c5b;
                }

                &.live-now {
                  animation: blinkingText 1.2s infinite;

                  @keyframes blinkingText {
                    0% {
                      color: #f85c5b;
                    }
                    49% {
                      color: #f85c5b;
                    }
                    60% {
                      color: transparent;
                    }
                    99% {
                      color: transparent;
                    }
                    100% {
                      color: #f85c5b;
                    }
                  }
                }
              }
              .vid-views {
                padding: 4px 6px;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                i {
                  margin-right: 6px;
                  font-size: 0.775rem;
                }
              }
            }
            .vid-title {
              .v-title {
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 800;
                text-transform: uppercase;
                color: rgb(255, 255, 255);
                display: -webkit-box;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                overflow-wrap: normal;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                font-size: 1.6rem;
                line-height: 1.85rem;
              }
              .author-name {
                display: flex;
                align-items: center;
                margin-top: 15px;
                .auth-left {
                  display: flex;
                  align-items: center;
                }
                .auth-image {
                  width: 32px;
                  height: 32px;
                  border: solid 1px #fff;
                  border-radius: 50%;
                  position: relative;
                  overflow: hidden;
                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                  }
                }
                .auth-right {
                  display: flex;
                  flex-direction: column;
                  font-family: "Nunito Sans", sans-serif !important;
                  font-size: 0.85rem;
                  color: #fff;
                  font-weight: 800;
                  color: #fff;
                  .ref-fee {
                    color: #fff;
                  }
                  .inf-fee {
                    color: #fff;
                  }
                }
                .auth-title {
                  color: #fff;
                  font-size: 0.875rem;
                  margin-left: 8px;
                  font-family: "SegoeUI_Medium";
                }
              }
            }
          }

          &.default-image {
            align-items: center;
            justify-content: center;
            display: flex;
            background: rgba(33, 43, 92, 1);
            background: -moz-linear-gradient(
              top,
              rgba(33, 43, 92, 1) 0%,
              rgba(0, 12, 64, 1) 100%
            );
            background: -webkit-gradient(
              left top,
              left bottom,
              color-stop(0%, rgba(33, 43, 92, 1)),
              color-stop(100%, rgba(0, 12, 64, 1))
            );
            background: -webkit-linear-gradient(
              top,
              rgba(33, 43, 92, 1) 0%,
              rgba(0, 12, 64, 1) 100%
            );
            background: -o-linear-gradient(
              top,
              rgba(33, 43, 92, 1) 0%,
              rgba(0, 12, 64, 1) 100%
            );
            background: -ms-linear-gradient(
              top,
              rgba(33, 43, 92, 1) 0%,
              rgba(0, 12, 64, 1) 100%
            );
            background: linear-gradient(
              to bottom,
              rgba(33, 43, 92, 1) 0%,
              rgba(0, 12, 64, 1) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212b5c', endColorstr='#000c40', GradientType=0 );
            img {
              width: initial;
              height: initial;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1350px) {
    .influencer-layout-main {
        .influencer-layout-inner{
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
@media only screen and (max-width: 990px) {
    .influencer-layout-main {
        .influencer-layout-inner{
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media only screen and (max-width: 600px) {
    .influencer-layout-main {
        .influencer-layout-inner{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}